<template>
  <div>
    <el-form :model="searchForm">
      <el-form-item
        label="可见性"
        style="width: 100%; display: flex; flex-direction: row; justify-content: flex-end; align-items: flex-end"
      >
        <el-select v-model="searchForm.status" @change="bindData">
          <el-option value="" label="全部"></el-option>
          <el-option value="1" label="可见"></el-option>
          <el-option value="2" label="隐藏"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-table
      ref="submissionTable"
      :data="dataTable"
      style="margin-top:20px"
      border
      @row-click="openSub"
    >
      <el-table-column
        v-for="item in dataList"
        :key="item._id"
        :prop="item.prop"
        :type="item.type"
        :label="item.label"
        :width="item.width"
        :formatter="item.formatter"
      >
      </el-table-column>
      <el-table-column
        v-if="data_params && (!data_params.pid || !data_params.pid.length)"
        prop="reply_count"
        :type="Number"
        label="回复数"
        width="100"
      >
      </el-table-column>
      <el-table-column
        label="操作"
        fixed="right"
        width="100"
        v-if="view_type == 'edit'"
      >
        <template slot-scope="scope">
          <perButton
            perm="activity:set_comment_show"
            @click.native.stop="handleChangeShow(scope.row)"
            >{{ scope.row.show ? "隐藏" : "可见" }}</perButton
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="k-9sptci-footer">
      <el-pagination
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        @current-change="handleQuery()"
        :current-page.sync="pager.pageNum"
        layout="sizes, prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>

    <el-dialog
      title=""
      :visible.sync="subVisible"
      :close-on-click-modal="false"
    >
      <comment_list
        :view_type="view_type"
        :data_params="sub_params"
      ></comment_list>
    </el-dialog>
  </div>
</template>

<script>
import perButton from "@/components/perm/perButton";
export default {
  name: "comment_list",
  components: {
    perButton,
  },
  props: {
    data_params: {
      type: Object,
    },
    view_type: {
      type: String,
      default: "view",
    },
  },
  watch: {
    data_params: {
      handler: function() {
        this.searchForm.status = "";
        this.bindData();
      },
      immediate: true,
    },
  },
  data() {
    return {
      subVisible: false,
      sub_params: {},
      searchForm: {
        status: "",
      },
      pageSize: 10,
      pager: {
        total: 0,
        pageNum: 1,
      },
      dataTable: [],
      dataList: [
        {
          prop: "sender.name",
          label: "发布者",
          width: "150",
        },
        {
          prop: "content",
          label: "评论内容",
        },
        {
          prop: "createTime",
          label: "发布时间",
          width: "150",
          formatter: this.timeFormat,
        },
        {
          prop: "show",
          label: "可见性",
          width: "60",
          formatter: (row, column, cellValue, index) => {
            if (cellValue) {
              return "可见";
            } else {
              return "隐藏";
            }
          },
        },
      ],
    };
  },
  mounted() {
    this.bindData();
  },
  methods: {
    timeFormat(row, column, cellValue, index) {
      if (cellValue && cellValue.length) {
        return new Date(cellValue).format("yyyy/MM/dd hh:mm:ss");
      } else {
        return cellValue;
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pager.pageNum = 1;
      this.pager.total = 0;
      this.handleQuery(false);
    },
    async bindData() {
      this.dataTable = [];
      if (
        !this.data_params ||
        !this.data_params.act_id ||
        !this.data_params.act_id.length ||
        !this.data_params.submission_id ||
        !this.data_params.submission_id.length
      ) {
        return;
      }

      await this.handleQuery(true);
    },
    async handleQuery(ref) {
      if (ref == true) {
        this.pager.pageNum = 0;
        this.pager.total = 0;
      }
      const { pageNum } = this.pager;
      const { data, errorCode } = await this.$http.commentList({
        act_id: this.data_params.act_id,
        sub_id: this.data_params.submission_id,
        pid: this.data_params.pid,
        ...this.searchForm,
        pageNum,
        pageSize: this.pageSize,
      });
      if (errorCode != "0000") return;
      this.dataTable = data.data;
      this.pager = data.pager;
    },
    async handleChangeShow(row) {
      let { data, errorCode } = await this.$http.changeCommentShow({
        act_id: this.data_params.act_id,
        sub_id: this.data_params.submission_id,
        _id: row._id,
        type: this.data_params.type,
        show: row.show ? "0" : "1",
      });

      if (errorCode != "0000") {
        this.$message.error("设置失败");
        return;
      }

      row.show = data.data.show;
    },
    openSub(row) {
      if (this.data_params.type == "sub") {
        return;
      }

      this.sub_params = {
        act_id: this.data_params.act_id,
        submission_id: this.data_params.submission_id,
        pid: row._id,
        type: "sub",
      };
      this.subVisible = true;
    },
  },
};
</script>

<style scoped></style>
