<template>
  <div>
    <comment_list :data_params="data_params" view_type="edit"></comment_list>
  </div>
</template>

<script>
import comment_list from "./comment_list";
export default {
  name: "comment_manager",
  components: {comment_list},
  data() {
    return {
      data_params: {},
    }
  },
  async mounted() {
    let _id = this.$route.query._id;
    let act_id = this.$route.query.act_id;
    this.data_params = {
      act_id: act_id,
      submission_id: _id,
      type: "parent"
    };
  }
}
</script>

<style scoped>

</style>
